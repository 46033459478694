import React, { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Contact } from '../customTypes';
import axiosApiInstance from '../axiosApi';

interface ContactSelectionModalProps {
	isOpen: boolean;
	onClose: () => void;
	onContactSelected: (contact: Contact) => void;
	companyId: number | null;
}

const ContactSelectionModal: React.FC<ContactSelectionModalProps> = ({
	isOpen,
	onClose,
	onContactSelected,
	companyId,
}) => {
	const [contacts, setContacts] = useState<Contact[]>([]);

	useEffect(() => {
		if (isOpen && companyId !== null) {
			fetchContacts();
		}
	}, [isOpen, companyId]);

	const fetchContacts = async () => {
		try {
			const response = await axiosApiInstance.get(
				`/newstracker/contacts/?company=${companyId}`
			);
			setContacts(response.data);
		} catch (error) {
			console.error('Error fetching contacts:', error);
		}
	};

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-10' onClose={onClose}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className='fixed inset-0 bg-black bg-opacity-25' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'
						>
							<Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
								<Dialog.Title
									as='h3'
									className='text-lg font-medium leading-6 text-gray-900'
								>
									Select a Contact
								</Dialog.Title>
								<div className='mt-2'>
									{contacts.length > 0 ? (
										<ul className='divide-y divide-gray-200'>
											{contacts.map((contact) => (
												<li
													key={contact.contact_id}
													className='py-4 cursor-pointer hover:bg-gray-50'
													onClick={() => onContactSelected(contact)}
												>
													<div className='flex items-center space-x-4'>
														<div className='flex-shrink-0'>
															<span className='inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100'>
																<svg
																	className='h-full w-full text-gray-300'
																	fill='currentColor'
																	viewBox='0 0 24 24'
																>
																	<path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
																</svg>
															</span>
														</div>
														<div className='flex-1 min-w-0'>
															<p className='text-sm font-medium text-gray-900 truncate'>
																{contact.first_name} {contact.last_name}
															</p>
															<p className='text-sm text-gray-500 truncate'>
																{contact.job_title}
															</p>
															<p className='text-sm text-gray-500 truncate'>
																{contact.email}
															</p>
														</div>
													</div>
												</li>
											))}
										</ul>
									) : (
										<p className='text-sm text-gray-500'>
											No contacts found for this company.
										</p>
									)}
								</div>
								<div className='mt-4'>
									<button
										type='button'
										className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
										onClick={onClose}
									>
										Cancel
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

export default ContactSelectionModal;
