import React, { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import axiosApiInstance from '../axiosApi';
import MarkdownDisplay from './MarkdownDisplay';
import { Spinner } from './Spinner';

interface ChatModalProps {
	isOpen: boolean;
	onClose: () => void;
	articleId: number | null;
	chatHistory: Array<{ role: string; content: string }>;
	updateChatHistory: (
		articleId: number,
		newHistory: Array<{ role: string; content: string }>
	) => void;
}

const ChatModal: React.FC<ChatModalProps> = ({
	isOpen,
	onClose,
	articleId,
	chatHistory,
	updateChatHistory,
}) => {
	const [message, setMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const handleSendMessage = async () => {
		if (!message.trim() || !articleId) return;

		setIsLoading(true);
		const newMessage = { role: 'user', content: message };
		const updatedHistory = [...chatHistory, newMessage];
		updateChatHistory(articleId, updatedHistory);

		try {
			const response = await axiosApiInstance.post('/newstracker/chat/', {
				article_id: articleId,
				message: message,
				chat_history: updatedHistory, // Include the updated chat history
			});

			const aiResponse = { role: 'assistant', content: response.data.message };
			updatedHistory.push(aiResponse);
			updateChatHistory(articleId, updatedHistory);
		} catch (error) {
			console.error('Error sending message:', error);
			// Handle error (e.g., show an error message to the user)
		} finally {
			setIsLoading(false);
			setMessage('');
		}
	};

	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			className='fixed inset-0 z-10 overflow-y-auto'
		>
			<div className='flex items-center justify-center min-h-screen p-4'>
				<Dialog.Overlay className='fixed inset-0 bg-black opacity-30' />
				<div className='relative bg-white rounded-lg w-full max-w-4xl mx-auto p-6 flex flex-col h-[90vh]'>
					<Dialog.Title className='text-xl font-medium mb-4'>
						Chat about this article
					</Dialog.Title>
					<div className='flex-grow overflow-y-auto mb-4 border rounded p-4'>
						{chatHistory.map((msg, index) => (
							<div
								key={index}
								className={`mb-4 ${
									msg.role === 'user' ? 'text-right' : 'text-left'
								}`}
							>
								{msg.role === 'user' ? (
									<span className='inline-block p-3 rounded bg-blue-100 max-w-[80%]'>
										{msg.content}
									</span>
								) : (
									<div className='inline-block p-3 rounded bg-gray-100 max-w-[80%]'>
										<MarkdownDisplay content={msg.content} />
									</div>
								)}
							</div>
						))}
						{isLoading && (
							<div className='flex justify-center items-center mt-4'>
								<Spinner className='h-8 w-8 text-blue-500' />
							</div>
						)}
					</div>
					<div className='flex'>
						<input
							type='text'
							value={message}
							onChange={(e) => setMessage(e.target.value)}
							className='flex-grow border rounded-l px-4 py-2 text-lg'
							placeholder='Type your message...'
						/>
						<button
							onClick={handleSendMessage}
							disabled={isLoading}
							className='bg-blue-500 text-white px-6 py-2 rounded-r text-lg font-medium'
						>
							{isLoading ? 'Sending...' : 'Send'}
						</button>
					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default ChatModal;
