import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Components } from 'react-markdown';

interface MarkdownDisplayProps {
	content?: string;
}

const MarkdownDisplay: React.FC<MarkdownDisplayProps> = ({ content = '' }) => {
	const convertedContent = content.replace(/^∙\s/gm, '* ');

	const components: Components = {
		code({ className, children, ...props }: any) {
			const match = /language-(\w+)/.exec(className || '');
			return match ? (
				<SyntaxHighlighter
					{...props}
					style={tomorrow}
					language={match[1]}
					PreTag='div'
				>
					{String(children).replace(/\n$/, '')}
				</SyntaxHighlighter>
			) : (
				<code className={className} {...props}>
					{children}
				</code>
			);
		},
		// Customize list rendering
		ul({ children }) {
			return <ul className='list-disc pl-6 space-y-3 mb-6'>{children}</ul>;
		},
		ol({ children }) {
			return <ol className='list-decimal pl-6 space-y-3 mb-6'>{children}</ol>;
		},
		// Customize list item rendering
		li({ children }) {
			return <li className='mb-2 leading-relaxed'>{children}</li>;
		},
		// Customize paragraph rendering
		p({ children }) {
			return <p className='mb-4 leading-relaxed'>{children}</p>;
		},
		// Customize heading rendering
		h1: ({ children }) => (
			<h1 className='text-2xl font-bold mb-6 mt-8'>{children}</h1>
		),
		h2: ({ children }) => (
			<h2 className='text-xl font-bold mb-4 mt-6'>{children}</h2>
		),
		h3: ({ children }) => (
			<h3 className='text-lg font-bold mb-3 mt-5'>{children}</h3>
		),
		h4: ({ children }) => (
			<h4 className='text-base font-bold mb-2 mt-4'>{children}</h4>
		),
		// Add strong (bold) component
		strong: ({ children }) => (
			<strong className='font-semibold'>{children}</strong>
		),
	};

	return (
		<div className='markdown-content space-y-4 max-w-2xl mx-auto leading-relaxed'>
			<ReactMarkdown remarkPlugins={[remarkGfm]} components={components}>
				{convertedContent}
			</ReactMarkdown>
		</div>
	);
};

export default MarkdownDisplay;
