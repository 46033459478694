import React, { useEffect, useState } from 'react';
import axiosApiInstance from '../axiosApi';
import { UserCompany } from '../customTypes';

const Watchlist: React.FC = () => {
	const [companies, setCompanies] = useState<UserCompany[]>([]);
	const [loading, setLoading] = useState(true);
	const [newCompanyName, setNewCompanyName] = useState('');

	useEffect(() => {
		fetchWatchlist();
	}, []);

	const fetchWatchlist = async () => {
		try {
			const response = await axiosApiInstance.get(
				'/newstracker/usercompanies/'
			); // Adjust the endpoint as necessary
			setCompanies(response.data);
		} catch (error) {
			console.error('Error fetching watchlist:', error);
		} finally {
			setLoading(false);
		}
	};

	const handleRemoveCompany = async (companyId: number) => {
		try {
			await axiosApiInstance.delete(`/newstracker/usercompanies/${companyId}/`); // Adjust the endpoint as necessary
			setCompanies(
				companies.filter((company) => company.usercompany_id !== companyId)
			);
		} catch (error) {
			console.error('Error removing company:', error);
		}
	};

	const handleAddCompany = async () => {
		if (newCompanyName.trim() === '') {
			alert('Please enter a company name.');
			return;
		}
		try {
			const response = await axiosApiInstance.post(
				'/newstracker/usercompanies/add_company/',
				{
					company: { name: newCompanyName },
				}
			); // Adjust the endpoint and payload as necessary
			setCompanies([...companies, response.data]);
			setNewCompanyName('');
		} catch (error) {
			console.error('Error adding company:', error);
		}
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			handleAddCompany();
		}
	};

	return (
		<div className='bg-white rounded-lg shadow p-4'>
			<h2 className='text-lg font-semibold mb-4'>Watchlist</h2>
			<div className='mb-4 flex'>
				<input
					type='text'
					value={newCompanyName}
					onChange={(e) => setNewCompanyName(e.target.value)}
					onKeyDown={handleKeyDown} // Added onKeyDown handler
					placeholder='Enter company name'
					className='flex-grow border border-gray-300 rounded-l px-3 py-2 focus:outline-none'
				/>
				<button
					onClick={handleAddCompany}
					className='bg-blue-500 text-white px-4 py-2 rounded-r-lg shadow hover:bg-blue-600 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-blue-400'
				>
					Add
				</button>
			</div>
			{loading ? (
				<p className='text-center text-gray-500'>Loading...</p>
			) : (
				<div className='overflow-x-auto'>
					<table className='min-w-full divide-y divide-gray-200'>
						<thead className='bg-gray-50'>
							<tr>
								<th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
									Company
								</th>
								<th className='px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider'>
									Actions
								</th>
							</tr>
						</thead>
						<tbody className='bg-white divide-y divide-gray-200'>
							{companies.map((company) => (
								<tr key={company.usercompany_id} className='hover:bg-gray-50'>
									<td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
										{company.company.name}
									</td>
									<td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right'>
										<button
											onClick={() =>
												handleRemoveCompany(company.usercompany_id)
											}
											className='text-red-600 hover:text-red-900'
										>
											Remove
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}
		</div>
	);
};

export default Watchlist;
