import React, {
	useState,
	FormEvent,
	FunctionComponent,
	useEffect,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import Cookies from 'js-cookie';
import prospectMonitorLogo from './assets/prospectmonitor.png';

interface SignInProps {
	setIsAuthenticated: (value: boolean) => void;
}

const SignIn: FunctionComponent<SignInProps> = ({ setIsAuthenticated }) => {
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [error, setError] = useState<string | null>(null);
	const navigate = useNavigate();
	const location = useLocation();
	const [hubspotConnect, setHubspotConnect] = useState<boolean>(false);
	const apiBaseUrl =
		process.env.REACT_APP_NODE_ENV === 'production'
			? '/api' // Use /api for production (handled by Nginx)
			: 'http://localhost:8000/api'; // Use localhost during development

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const hubspotConnectParam = searchParams.get('hubspot_connect');
		setHubspotConnect(hubspotConnectParam === 'true');
		console.log('hubspotConnect:', hubspotConnectParam);

		// If user is already authenticated and hubspot_connect is true, initiate HubSpot OAuth
		if (
			localStorage.getItem('access_token') &&
			hubspotConnectParam === 'true'
		) {
			initiateHubSpotOAuth();
		}
	}, [location.search]);

	useEffect(() => {
		// Listen for messages from the Chrome extension
		window.addEventListener('message', handleExtensionMessage);
		return () => {
			window.removeEventListener('message', handleExtensionMessage);
		};
	}, []);

	const handleExtensionMessage = (event: MessageEvent) => {
		if (event.source !== window) return;
		if (event.data.type && event.data.type === 'REQUEST_TOKEN') {
			// Send the token to the Chrome extension
			window.postMessage(
				{ type: 'SEND_TOKEN', token: localStorage.getItem('access_token') },
				'*'
			);
		}
	};

	const initiateHubSpotOAuth = async () => {
		try {
			const response = await axios.get(
				`${apiBaseUrl}/authentication/hubspot/connect/`,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('access_token')}`,
					},
				}
			);
			if (response.data.hubspot_redirect_url) {
				window.location.href = response.data.hubspot_redirect_url;
			}
		} catch (error) {
			console.error('Error initiating HubSpot OAuth:', error);
		}
	};

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const csrfToken = Cookies.get('csrftoken');
		console.log('Submitting with hubspotConnect:', hubspotConnect);
		const data = qs.stringify({
			email: email,
			password: password,
			username: email,
		});

		try {
			const response = await axios.post(
				`${apiBaseUrl}/authentication/token/`,
				data,
				{
					headers: {
						'X-CSRFToken': csrfToken,
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				}
			);

			if (response.data.access) {
				localStorage.setItem('access_token', response.data.access);
				localStorage.setItem('refresh_token', response.data.refresh);
				setIsAuthenticated(true);

				// Send token to Chrome extension
				window.postMessage(
					{ type: 'SEND_TOKEN', token: response.data.access },
					'*'
				);

				if (hubspotConnect) {
					await initiateHubSpotOAuth();
				} else {
					navigate('/dashboard');
				}
			}
		} catch (err) {
			if (axios.isAxiosError(err) && err.response) {
				setError('Login failed. Please check your credentials and try again.');
			} else {
				setError('An unexpected error occurred. Please try again later.');
			}
			console.error('Login error', err);
		}
	};

	const handleSignUpRedirect = () => {
		navigate('/signup');
	};

	return (
		<>
			<div className='flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
				<div className='sm:mx-auto sm:w-full sm:max-w-sm'>
					<img
						className='mx-auto h-12 w-auto'
						src={prospectMonitorLogo}
						alt='Company Logo'
					/>
					<h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
						Sign in to your account
					</h2>
				</div>

				<div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
					{error && (
						<div
							className='mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative'
							role='alert'
						>
							<span className='block sm:inline'>{error}</span>
						</div>
					)}
					<form className='space-y-6' onSubmit={handleSubmit}>
						<div>
							<label
								htmlFor='email'
								className='block text-sm font-medium leading-6 text-gray-900'
							>
								Email address
							</label>
							<div className='mt-2'>
								<input
									id='email'
									name='email'
									type='email'
									autoComplete='email'
									required
									className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
						</div>

						<div>
							<div className='flex items-center justify-between'>
								<label
									htmlFor='password'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									Password
								</label>
								<div className='text-sm'>
									<a
										href='/password-reset'
										className='font-semibold text-blue-600 hover:text-blue-500'
									>
										Forgot password?
									</a>
								</div>
							</div>
							<div className='mt-2'>
								<input
									id='password'
									name='password'
									type='password'
									autoComplete='current-password'
									required
									className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
						</div>

						<div>
							<button
								type='submit'
								className='flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
							>
								Sign in
							</button>
						</div>
					</form>

					<p className='mt-10 text-center text-sm text-gray-500'>
						Not a member?{' '}
						<button
							onClick={handleSignUpRedirect}
							className='font-semibold text-blue-600 hover:text-blue-500'
						>
							Register for our closed Beta.
						</button>
					</p>
				</div>
			</div>
		</>
	);
};

export default SignIn;
