import React, { useState, useEffect, useMemo } from 'react';
import axiosApiInstance from '../axiosApi';
import { PlusIcon } from '@heroicons/react/24/outline';

interface Trigger {
	trigger_id: number;
	name: string;
	description: string;
}

const TriggersTable: React.FC = () => {
	const [triggers, setTriggers] = useState<Trigger[]>([]);
	const [newTriggerName, setNewTriggerName] = useState('');
	const [newTriggerDescription, setNewTriggerDescription] = useState('');
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchTriggers();
	}, []);

	const fetchTriggers = async () => {
		try {
			const response = await axiosApiInstance.get<Trigger[]>(
				'/newstracker/triggers/'
			);
			setTriggers(response.data);
		} catch (error) {
			console.error('Error fetching triggers:', error);
			alert('Failed to load triggers.');
		} finally {
			setLoading(false);
		}
	};

	// Memoize the unique triggers to avoid unnecessary recalculations
	const uniqueTriggers = useMemo(() => {
		const triggerMap = new Map<string, Trigger>();
		triggers.forEach((trigger) => {
			if (!triggerMap.has(trigger.name)) {
				triggerMap.set(trigger.name, trigger);
			}
		});
		return Array.from(triggerMap.values());
	}, [triggers]);

	const handleAddTrigger = async () => {
		if (!newTriggerName.trim()) {
			alert('Trigger name is required.');
			return;
		}
		try {
			const response = await axiosApiInstance.post<Trigger>(
				'/newstracker/triggers/',
				{
					name: newTriggerName,
					description: newTriggerDescription,
				}
			);
			// Since triggers are created for all profiles, fetch triggers again
			fetchTriggers();
			setNewTriggerName('');
			setNewTriggerDescription('');
		} catch (error) {
			console.error('Error adding trigger:', error);
			alert('Failed to add trigger.');
		}
	};

	const handleDeleteTrigger = async (trigger_id: number) => {
		if (!window.confirm('Are you sure you want to delete this trigger?'))
			return;
		try {
			await axiosApiInstance.delete(`/newstracker/triggers/${trigger_id}/`);
			// Since triggers are deleted for all profiles, fetch triggers again
			fetchTriggers();
		} catch (error) {
			console.error('Error deleting trigger:', error);
			alert('Failed to delete trigger.');
		}
	};

	if (loading) return <p>Loading triggers...</p>;

	return (
		<div className='bg-white p-6 rounded-lg shadow-md'>
			<h2 className='text-xl font-bold mb-4'>Trigger Event</h2>
			{uniqueTriggers.length === 0 ? (
				<p className='text-gray-500'>
					No triggers found. Add a new trigger below.
				</p>
			) : (
				<table className='min-w-full divide-y divide-gray-200'>
					<thead className='bg-gray-50'>
						<tr>
							<th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
								Name
							</th>
							<th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
								Description
							</th>
							<th className='px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider'>
								Actions
							</th>
						</tr>
					</thead>
					<tbody className='bg-white divide-y divide-gray-200'>
						{uniqueTriggers.map((trigger) => (
							<tr key={trigger.trigger_id}>
								<td className='px-6 py-4 whitespace-nowrap'>{trigger.name}</td>
								<td className='px-6 py-4 whitespace-nowrap'>
									{trigger.description}
								</td>
								<td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium'>
									<button
										onClick={() => handleDeleteTrigger(trigger.trigger_id)}
										className='text-red-600 hover:text-red-900'
									>
										Remove
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}

			<div className='mt-6'>
				<h3 className='text-lg font-semibold mb-2'>Add New Trigger</h3>
				<div className='flex flex-col space-y-4'>
					<input
						type='text'
						placeholder='Name'
						value={newTriggerName}
						onChange={(e) => setNewTriggerName(e.target.value)}
						className='border border-gray-300 rounded-md p-2'
					/>
					<textarea
						placeholder='Explanation of what you want to track'
						value={newTriggerDescription}
						onChange={(e) => setNewTriggerDescription(e.target.value)}
						className='border border-gray-300 rounded-md p-2'
						rows={3}
					></textarea>
					<button
						onClick={handleAddTrigger}
						className='bg-blue-500 text-white px-4 py-2 rounded-md flex items-center'
					>
						<PlusIcon className='h-5 w-5 mr-2' />
						Add Trigger
					</button>
				</div>
			</div>
		</div>
	);
};

export default TriggersTable;
