import React, { useState, useEffect } from 'react';
import { NewsEvent, Contact } from '../customTypes';
import {
	ChevronDownIcon,
	ChevronUpIcon,
	EnvelopeIcon,
	DocumentCheckIcon,
	ChatBubbleLeftEllipsisIcon,
} from '@heroicons/react/24/outline';
import MarkdownDisplay from './MarkdownDisplay';
import ContactSelectionModal from './ContactSelectionModal';
import axiosApiInstance from '../axiosApi';
import GeneratedEmailModal from './GeneratedEmailModal';
import { Spinner } from './Spinner';
import ChatModal from './ChatModal'; // We'll create this component

interface NewsTableProps {
	newsEvents: NewsEvent[];
}

const NewsTable: React.FC<NewsTableProps> = ({ newsEvents }) => {
	const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());
	const [isContactModalOpen, setIsContactModalOpen] = useState(false);
	const [selectedEvent, setSelectedEvent] = useState<NewsEvent | null>(null);
	const [generatedEmail, setGeneratedEmail] = useState<string | null>(null);
	const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
	const [events, setEvents] = useState<NewsEvent[]>(newsEvents);
	const [summarizingArticles, setSummarizingArticles] = useState<Set<number>>(
		new Set()
	);
	const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
	const [generatingEmailForEvent, setGeneratingEmailForEvent] = useState<
		number | null
	>(null);
	const [isChatModalOpen, setIsChatModalOpen] = useState(false);
	const [selectedArticleId, setSelectedArticleId] = useState<number | null>(
		null
	);
	const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(
		null
	);
	const [chatHistories, setChatHistories] = useState<{
		[key: number]: Array<{ role: string; content: string }>;
	}>({});

	const toggleRow = (articleId: number) => {
		setExpandedRows((prevState) => {
			const newState = new Set(prevState);
			if (newState.has(articleId)) {
				newState.delete(articleId);
			} else {
				newState.add(articleId);
			}
			return newState;
		});
	};

	const handleGenerateEmail = async (event: NewsEvent) => {
		setSelectedEvent(event);
		setGeneratingEmailForEvent(event.article_id);
		const companyId = event.company.company_id;
		setSelectedCompanyId(companyId);
		setIsContactModalOpen(true);
	};

	const handleContactSelected = async (contact: Contact) => {
		setIsContactModalOpen(false);
		setSelectedContact(contact);
		if (selectedEvent) {
			await generateEmail(selectedEvent, contact);
		}
		setGeneratingEmailForEvent(null);
	};

	const generateEmail = async (event: NewsEvent, contact: Contact) => {
		try {
			const response = await axiosApiInstance.post(
				'/newstracker/generate-email/',
				{
					article_id: event.article_id,
					company: event.company.name,
					contact: contact,
				}
			);
			setGeneratedEmail(response.data.email);
			setIsEmailModalOpen(true);
		} catch (error) {
			console.error('Error generating email:', error);
			// Handle error (e.g., show an error message to the user)
		}
	};

	const handleSummarize = async (event: NewsEvent) => {
		setSummarizingArticles((prev) => new Set(prev).add(event.article_id));
		try {
			const response = await axiosApiInstance.post(
				'/newstracker/summarize-article/',
				{
					article_id: event.article_id,
				}
			);
		} catch (error) {
			console.error('Error generating summary:', error);
		}
	};

	const handleRegenerateEmail = async () => {
		if (selectedEvent && selectedContact) {
			await generateEmail(selectedEvent, selectedContact);
		}
	};

	const handleOpenChat = (articleId: number) => {
		setSelectedArticleId(articleId);
		setIsChatModalOpen(true);
		// Initialize chat history for this article if it doesn't exist
		if (!chatHistories[articleId]) {
			setChatHistories((prev) => ({ ...prev, [articleId]: [] }));
		}
	};

	const updateChatHistory = (
		articleId: number,
		newHistory: Array<{ role: string; content: string }>
	) => {
		setChatHistories((prev) => ({ ...prev, [articleId]: newHistory }));
	};

	return (
		<div className='overflow-x-auto'>
			<table className='min-w-full divide-y divide-gray-200'>
				<thead className='bg-gray-50'>
					<tr>
						<th
							scope='col'
							className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
						>
							Account
						</th>
						<th
							scope='col'
							className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
						>
							Event
						</th>
						<th
							scope='col'
							className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
						>
							Date
						</th>
						<th scope='col' className='relative px-6 py-3'>
							<span className='sr-only'>Actions</span>
						</th>
					</tr>
				</thead>
				<tbody className='bg-white divide-y divide-gray-200'>
					{newsEvents.map((event) => (
						<React.Fragment key={event.article_id}>
							<tr className='hover:bg-gray-50'>
								<td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
									{event.company.name}
								</td>
								<td className='px-6 py-4'>
									<div className='text-sm font-medium text-gray-900'>
										<a
											href={event.url}
											target='_blank'
											rel='noopener noreferrer'
											className='text-indigo-600 hover:text-indigo-900'
										>
											{event.title}
										</a>
									</div>
								</td>
								<td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
									{new Date(event.published_date).toLocaleString()}
								</td>
								<td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium'>
									<div className='flex justify-end space-x-2'>
										{event.impact_analysis && (
											<button
												onClick={() => toggleRow(event.article_id)}
												className='text-gray-400 hover:text-gray-600'
											>
												{expandedRows.has(event.article_id) ? (
													<ChevronUpIcon className='h-5 w-5' />
												) : (
													<ChevronDownIcon className='h-5 w-5' />
												)}
											</button>
										)}
										<button
											onClick={() => handleGenerateEmail(event)}
											className='text-indigo-600 hover:text-indigo-900'
											title='Generate Cold Email'
											disabled={generatingEmailForEvent === event.article_id}
										>
											{generatingEmailForEvent === event.article_id ? (
												<Spinner className='h-5 w-5 text-indigo-600' />
											) : (
												<EnvelopeIcon className='h-5 w-5' />
											)}
										</button>
										<button
											onClick={() => handleSummarize(event)}
											className='text-indigo-600 hover:text-indigo-900'
											title='Summarize with Plugin'
											disabled={summarizingArticles.has(event.article_id)}
										>
											{summarizingArticles.has(event.article_id) &&
											!event.impact_analysis ? (
												<Spinner className='h-5 w-5' />
											) : (
												<DocumentCheckIcon className='h-5 w-5' />
											)}
										</button>
										<button
											onClick={() => handleOpenChat(event.article_id)}
											className='text-indigo-600 hover:text-indigo-900'
											title='Chat about this article'
										>
											<ChatBubbleLeftEllipsisIcon className='h-5 w-5' />
										</button>
									</div>
								</td>
							</tr>
							{event.impact_analysis && expandedRows.has(event.article_id) && (
								<tr>
									<td
										colSpan={4}
										className='px-6 py-4 text-sm text-black bg-gray-50'
									>
										<div className='prose max-w-none'>
											<MarkdownDisplay content={event.impact_analysis} />
										</div>
									</td>
								</tr>
							)}
						</React.Fragment>
					))}
				</tbody>
			</table>
			<ContactSelectionModal
				isOpen={isContactModalOpen}
				onClose={() => setIsContactModalOpen(false)}
				onContactSelected={handleContactSelected}
				companyId={selectedCompanyId}
			/>
			<GeneratedEmailModal
				isOpen={isEmailModalOpen}
				onClose={() => setIsEmailModalOpen(false)}
				email={generatedEmail}
				onRegenerate={handleRegenerateEmail}
			/>
			<ChatModal
				isOpen={isChatModalOpen}
				onClose={() => setIsChatModalOpen(false)}
				articleId={selectedArticleId}
				chatHistory={
					selectedArticleId ? chatHistories[selectedArticleId] || [] : []
				}
				updateChatHistory={updateChatHistory}
			/>
		</div>
	);
};

export default NewsTable;
