import React, { useState, useEffect, FormEvent } from 'react';
import axiosApiInstance from '../axiosApi';

interface UserSettingsProps {
	first_name: string;
	last_name: string;
	username: string;
	email: string;
	settings: {
		email_frequency: string | null;
		email_template: string | null;
		sales_profile: string | null;
	};
}

const emailFrequencies = [
	'daily',
	'weekly',
	'monthly',
	'quarterly',
	'annually',
	'none',
];

const UserSettings: React.FC = () => {
	const [userSettings, setUserSettings] = useState<UserSettingsProps>({
		first_name: '',
		last_name: '',
		username: '',
		email: '',
		settings: {
			email_frequency: 'daily',
			email_template: '',
			sales_profile: '',
		},
	});
	const [isLoading, setIsLoading] = useState(false);
	const [message, setMessage] = useState('');

	useEffect(() => {
		fetchUserSettings();
	}, []);

	const fetchUserSettings = () => {
		axiosApiInstance
			.get<UserSettingsProps>(`/authentication/usersettings/`)
			.then((response) => {
				setUserSettings(response.data);
			})
			.catch((err) => console.error(err));
	};

	const handleInputChange = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>
	) => {
		const { name, value } = event.target;
		setUserSettings((prevSettings) => ({
			...prevSettings,
			settings: {
				...prevSettings.settings,
				[name]: value,
			},
		}));
	};

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();
		setIsLoading(true);
		setMessage('');

		try {
			await axiosApiInstance.patch(`/authentication/usersettings/`, {
				settings: userSettings.settings,
			});
			setMessage('Settings updated successfully!');
		} catch (err) {
			console.error(err);
			setMessage('Failed to update settings. Please try again.');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<div className='mx-auto max-w-7xl pt-16 lg:flex lg:gap-x-16 lg:px-8'>
				<h1 className='sr-only'>General Settings</h1>
				<main className='px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20'>
					<div className='mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none'>
						<form onSubmit={handleSubmit}>
							<div>
								<h2 className='text-base font-semibold leading-7 text-gray-900'>
									Profile
								</h2>
								<p className='mt-1 text-sm leading-6 text-gray-500'>
									Your account information
								</p>

								<dl className='mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6'>
									<div className='pt-6 sm:flex'>
										<dt className='font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6'>
											First name
										</dt>
										<dd className='mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto'>
											<div className='text-gray-900'>
												{userSettings.first_name}
											</div>
											<button
												type='button'
												className='font-semibold text-blue-600 hover:text-blue-500'
											>
												Edit
											</button>
										</dd>
									</div>
									<div className='pt-6 sm:flex'>
										<dt className='font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6'>
											Last name
										</dt>
										<dd className='mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto'>
											<div className='text-gray-900'>
												{userSettings.last_name}
											</div>
											<button
												type='button'
												className='font-semibold text-blue-600 hover:text-blue-500'
											>
												Edit
											</button>
										</dd>
									</div>
									<div className='pt-6 sm:flex'>
										<dt className='font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6'>
											Username
										</dt>
										<dd className='mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto'>
											<div className='text-gray-900'>
												{userSettings.username}
											</div>
											<button
												type='button'
												className='font-semibold text-blue-600 hover:text-blue-500'
											>
												Edit
											</button>
										</dd>
									</div>
									<div className='pt-6 sm:flex'>
										<dt className='font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6'>
											Email address
										</dt>
										<dd className='mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto'>
											<div className='text-gray-900'>{userSettings.email}</div>
											<button
												type='button'
												className='font-semibold text-blue-600 hover:text-blue-500'
											>
												Edit
											</button>
										</dd>
									</div>
									<div className='pt-6 sm:flex'>
										<dt className='font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6'>
											Password
										</dt>
										<dd className='mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto'>
											<div className='text-gray-900'>****************</div>
											<button
												type='button'
												className='font-semibold text-blue-600 hover:text-blue-500'
											>
												Reset my password
											</button>
										</dd>
									</div>
									<div className='pt-6 sm:flex'>
										<dt className='font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6'>
											Email Frequency
										</dt>
										<dd className='mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto'>
											<select
												id='email_frequency'
												name='email_frequency'
												value={userSettings.settings.email_frequency || 'none'}
												onChange={handleInputChange}
												className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
											>
												{emailFrequencies.map((frequency) => (
													<option key={frequency} value={frequency}>
														{frequency.charAt(0).toUpperCase() +
															frequency.slice(1)}
													</option>
												))}
											</select>
										</dd>
									</div>
									<div className='pt-6 sm:flex'>
										<dt className='font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6'>
											Email Template
										</dt>
										<dd className='mt-1 sm:mt-0 sm:flex-auto'>
											<textarea
												name='email_template'
												rows={4}
												value={userSettings.settings.email_template || ''}
												onChange={handleInputChange}
												className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
											/>
										</dd>
									</div>
									<div className='pt-6 sm:flex'>
										<dt className='font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6'>
											Sales Profile
										</dt>
										<dd className='mt-1 sm:mt-0 sm:flex-auto'>
											<textarea
												name='sales_profile'
												rows={4}
												value={userSettings.settings.sales_profile || ''}
												onChange={handleInputChange}
												className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
											/>
										</dd>
									</div>
								</dl>
							</div>
							<div className='mt-6 flex items-center justify-end gap-x-6'>
								<button
									type='submit'
									disabled={isLoading}
									className='rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
								>
									{isLoading ? 'Saving...' : 'Save Changes'}
								</button>
							</div>
							{message && (
								<p
									className={`mt-2 text-sm ${
										message.includes('Failed')
											? 'text-red-600'
											: 'text-green-600'
									}`}
								>
									{message}
								</p>
							)}
						</form>
					</div>
				</main>
			</div>
		</>
	);
};

export default UserSettings;
