import React, { useState, useEffect } from 'react';
import { Contact, UserCompany } from '../customTypes';
import { PlusIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import axiosApiInstance from '../axiosApi'; // Import the axiosApiInstance

const Contacts: React.FC = () => {
	const [contacts, setContacts] = useState<Contact[]>([]);
	const [userCompanies, setUserCompanies] = useState<UserCompany[]>([]);
	const [isAdding, setIsAdding] = useState(false);
	const [isEditing, setIsEditing] = useState<number | null>(null);
	const [formData, setFormData] = useState<{
		first_name: string;
		last_name: string;
		job_title: string;
		usercompany_id: number;
		email: string;
	}>({
		first_name: '',
		last_name: '',
		job_title: '',
		usercompany_id: 0,
		email: '',
	});
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');

	useEffect(() => {
		fetchContacts();
		fetchUserCompanies();
	}, []);

	// Fetch contacts from the backend
	const fetchContacts = async () => {
		setLoading(true);
		try {
			const response = await axiosApiInstance.get('/newstracker/contacts/');
			setContacts(response.data);
		} catch (err: any) {
			console.error('Error fetching contacts:', err);
			setError('Failed to fetch contacts.');
		} finally {
			setLoading(false);
		}
	};

	// Fetch user companies from the backend
	const fetchUserCompanies = async () => {
		setLoading(true);
		try {
			const response = await axiosApiInstance.get(
				'/newstracker/usercompanies/'
			);
			setUserCompanies(response.data);
		} catch (err: any) {
			console.error('Error fetching user companies:', err);
			setError('Failed to fetch user companies.');
		} finally {
			setLoading(false);
		}
	};

	// Start adding a new contact
	const startAdding = () => {
		setIsAdding(true);
		setFormData({
			first_name: '',
			last_name: '',
			job_title: '',
			usercompany_id: 0,
			email: '',
		});
		setError('');
	};

	// Start editing an existing contact
	const startEditing = (contact: Contact) => {
		setIsEditing(contact.contact_id);
		setFormData({
			first_name: contact.first_name,
			last_name: contact.last_name,
			job_title: contact.job_title,
			usercompany_id: contact.usercompany.usercompany_id, // Set UserCompany ID
			email: contact.email,
		});
		setError('');
	};

	// Handle input changes
	const handleInputChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		const { name, value } = e.target;
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	// Handle adding a new contact
	const handleAddContact = async () => {
		if (formData.usercompany_id === 0) {
			setError('Please select a company.');
			return;
		}

		try {
			const payload = {
				first_name: formData.first_name,
				last_name: formData.last_name,
				job_title: formData.job_title,
				usercompany_id: formData.usercompany_id, // Use UserCompany ID
				email: formData.email,
			};
			const response = await axiosApiInstance.post(
				'/newstracker/contacts/',
				payload
			);
			setContacts([...contacts, response.data]);

			resetForm();
		} catch (err: any) {
			console.error('Error adding contact:', err);
			setError(
				err.response?.data?.email
					? err.response.data.email[0]
					: 'Failed to add contact.'
			);
		}
	};

	// Handle editing an existing contact
	const handleEditContact = async () => {
		if (isEditing === null) return;

		if (formData.usercompany_id === 0) {
			setError('Please select a company.');
			return;
		}

		try {
			const payload = {
				first_name: formData.first_name,
				last_name: formData.last_name,
				job_title: formData.job_title,
				usercompany_id: formData.usercompany_id, // Use UserCompany ID
				email: formData.email,
			};
			const response = await axiosApiInstance.put(
				`/newstracker/contacts/${isEditing}/`,
				payload
			);
			setContacts(
				contacts.map((contact) =>
					contact.contact_id === isEditing ? response.data : contact
				)
			);

			resetForm();
		} catch (err: any) {
			console.error('Error updating contact:', err);
			setError(
				err.response?.data?.email
					? err.response.data.email[0]
					: 'Failed to update contact.'
			);
		}
	};

	// Handle deleting a contact
	const handleDeleteContact = async (contactId: number) => {
		if (!window.confirm('Are you sure you want to delete this contact?'))
			return;

		try {
			await axiosApiInstance.delete(`/newstracker/contacts/${contactId}/`);
			setContacts(
				contacts.filter((contact) => contact.contact_id !== contactId)
			);
		} catch (err: any) {
			console.error('Error deleting contact:', err);
			setError('Failed to delete contact.');
		}
	};

	// Reset form states
	const resetForm = () => {
		setIsAdding(false);
		setIsEditing(null);
		setFormData({
			first_name: '',
			last_name: '',
			job_title: '',
			usercompany_id: 0,
			email: '',
		});
		setError('');
	};

	return (
		<div className='container mx-auto p-4'>
			<div className='flex justify-between items-center mb-6'>
				<h1 className='text-3xl font-semibold text-gray-800'>Contacts</h1>
				<button
					onClick={startAdding}
					className='flex items-center px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors'
				>
					<PlusIcon className='h-5 w-5 mr-2' aria-hidden='true' />
					Add Contact
				</button>
			</div>

			{error && (
				<div className='mb-4 p-4 bg-red-100 text-red-700 rounded-md'>
					{error}
				</div>
			)}

			<div className='overflow-x-auto'>
				<table className='min-w-full bg-white shadow rounded-lg overflow-hidden'>
					<thead className='bg-gray-200'>
						<tr>
							<th className='py-3 px-6 text-left text-sm font-medium text-gray-700 uppercase tracking-wider'>
								First Name
							</th>
							<th className='py-3 px-6 text-left text-sm font-medium text-gray-700 uppercase tracking-wider'>
								Last Name
							</th>
							<th className='py-3 px-6 text-left text-sm font-medium text-gray-700 uppercase tracking-wider'>
								Job Title
							</th>
							<th className='py-3 px-6 text-left text-sm font-medium text-gray-700 uppercase tracking-wider'>
								Company
							</th>
							<th className='py-3 px-6 text-left text-sm font-medium text-gray-700 uppercase tracking-wider'>
								Email
							</th>
							<th className='py-3 px-6 text-center text-sm font-medium text-gray-700 uppercase tracking-wider'>
								Actions
							</th>
						</tr>
					</thead>
					<tbody>
						{contacts.map((contact) => (
							<tr
								key={contact.contact_id}
								className='hover:bg-gray-50 transition-colors'
							>
								<td className='py-4 px-6 text-sm text-gray-700'>
									{contact.first_name}
								</td>
								<td className='py-4 px-6 text-sm text-gray-700'>
									{contact.last_name}
								</td>
								<td className='py-4 px-6 text-sm text-gray-700'>
									{contact.job_title}
								</td>
								<td className='py-4 px-6 text-sm text-gray-700'>
									{contact.usercompany.company.website ? (
										<a
											href={contact.usercompany.company.website}
											target='_blank'
											rel='noopener noreferrer'
											className='text-indigo-600 hover:text-indigo-800 underline'
										>
											{contact.usercompany.company.name}
										</a>
									) : (
										<span>{contact.usercompany.company.name}</span>
									)}
								</td>
								<td className='py-4 px-6 text-sm text-indigo-600 hover:text-indigo-800'>
									<a href={`mailto:${contact.email}`}>{contact.email}</a>
								</td>
								<td className='py-4 px-6 text-center'>
									<div className='flex items-center justify-center space-x-4'>
										<button
											onClick={() => startEditing(contact)}
											className='text-yellow-500 hover:text-yellow-700 transition-colors'
										>
											<PencilIcon className='h-5 w-5' aria-hidden='true' />
										</button>
										<button
											onClick={() => handleDeleteContact(contact.contact_id)}
											className='text-red-500 hover:text-red-700 transition-colors'
										>
											<TrashIcon className='h-5 w-5' aria-hidden='true' />
										</button>
									</div>
								</td>
							</tr>
						))}
						{contacts.length === 0 && !loading && (
							<tr>
								<td colSpan={6} className='py-4 px-6 text-center text-gray-500'>
									No contacts found.
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>

			{/* Add/Edit Form Modal */}
			{(isAdding || isEditing !== null) && (
				<div className='fixed z-20 inset-0 overflow-y-auto'>
					<div className='flex items-center justify-center min-h-screen px-4'>
						<div
							className='fixed inset-0 bg-black opacity-50'
							onClick={resetForm}
						></div>

						<div className='bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full z-30'>
							<div className='px-6 py-4'>
								<h3 className='text-xl font-semibold text-gray-800 mb-4'>
									{isAdding ? 'Add New Contact' : 'Edit Contact'}
								</h3>
								<div className='space-y-4'>
									<div>
										<label className='block text-sm font-medium text-gray-700 mb-1'>
											First Name
										</label>
										<input
											type='text'
											name='first_name'
											value={formData.first_name}
											onChange={handleInputChange}
											className='w-full border border-gray-300 rounded-md p-2 focus:ring-indigo-500 focus:border-indigo-500'
											required
										/>
									</div>
									<div>
										<label className='block text-sm font-medium text-gray-700 mb-1'>
											Last Name
										</label>
										<input
											type='text'
											name='last_name'
											value={formData.last_name}
											onChange={handleInputChange}
											className='w-full border border-gray-300 rounded-md p-2 focus:ring-indigo-500 focus:border-indigo-500'
											required
										/>
									</div>
									<div>
										<label className='block text-sm font-medium text-gray-700 mb-1'>
											Job Title
										</label>
										<input
											type='text'
											name='job_title'
											value={formData.job_title}
											onChange={handleInputChange}
											className='w-full border border-gray-300 rounded-md p-2 focus:ring-indigo-500 focus:border-indigo-500'
											required
										/>
									</div>
									<div>
										<label className='block text-sm font-medium text-gray-700 mb-1'>
											Company
										</label>
										<select
											name='usercompany_id'
											value={formData.usercompany_id}
											onChange={handleInputChange}
											className='w-full border border-gray-300 rounded-md p-2 focus:ring-indigo-500 focus:border-indigo-500'
											required
										>
											<option value={0}>Select a company</option>
											{userCompanies.map((userCompany) => (
												<option
													key={userCompany.usercompany_id}
													value={userCompany.usercompany_id}
												>
													{userCompany.company.name}{' '}
													{userCompany.notes ? ` - ${userCompany.notes}` : ''}
												</option>
											))}
										</select>
									</div>
									<div>
										<label className='block text-sm font-medium text-gray-700 mb-1'>
											Email
										</label>
										<input
											type='email'
											name='email'
											value={formData.email}
											onChange={handleInputChange}
											className='w-full border border-gray-300 rounded-md p-2 focus:ring-indigo-500 focus:border-indigo-500'
											required
										/>
									</div>
								</div>
							</div>
							<div className='px-6 py-3 bg-gray-50 flex justify-end space-x-3'>
								<button
									onClick={resetForm}
									className='px-4 py-2 bg-white text-gray-700 rounded-md border border-gray-300 hover:bg-gray-100 transition-colors'
								>
									Cancel
								</button>
								<button
									onClick={isAdding ? handleAddContact : handleEditContact}
									className='px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors'
								>
									{isAdding ? 'Add' : 'Save'}
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Contacts;
